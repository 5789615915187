import logo from './img_Logo.png';
import background from './img_Background.jpeg';
import './App.css';
import ParkMap from './ParkMap';
function App() {
  return (
    <div className="App">
      <header className="App-header" style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '48px',
            fontFamily: 'Garamond',
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 1)), url(${background})`,
            backgroundSize: 'cover',
      }}>
      <div >
      <b style={{
        color: 'black',
      }}>Smart Carpark Management System</b>
      <img src={logo} alt="logo" />
      </div>



    <div className="description" style={{
      display: 'flex',
      flexDirection: 'column',
      fontSize: '30px',
      fontFamily: 'Arial',
      // justifyContent: 'flex-start',
      // alignItems: 'flex-start',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <p style={{backgroundImage: 'img_Logo.png',}}></p>
      
      <p class = "p-border">
      
        <p style={{
          color: 'black',
        }}>The rectangles show the availability of the carpark slot: </p>
        
        {/* <p style={{
          color: 'green',
        }}><strong>Green:</strong> Not Occupied</p>
        
        <p style={{
          color: 'red',
        }}><strong>Red:</strong> Occupied</p> */}

        <p></p>
        
        {/* Label for "Not-Occupied" */}
        <div style={{
            position: 'absolute',
            left: '0',
            transform: 'translateX(120%) translateY(50%)',
            color: 'green',
          }}>
            <p>Not-Occupied</p>
          </div>

          {/* Label for "Occupied" */}
          <div style={{
            position: 'absolute',
            right: '0',
            transform: 'translateX(-170%) translateY(50%)',
            color: 'red',
          }}>
            <p>Occupied</p>
          </div>

        <div style={{
          width: '80%', // Adjust the width as needed
          height: '20px',
          background: 'linear-gradient(to right, green, red)',
          justifyContent: 'center',
          alignContent: 'center',
        }}></div>

      </p>

      <ParkMap />
    </div>
      </header>
    </div>
  );
}

export default App;
// idk y no photo