import React, { useEffect } from 'react'

// the map below is made by this js

export default function ParkMap(){
    
    const [parkingData,setparkingData]=React.useState({})
    const [nameMap,setNameMap]=React.useState({})
    useEffect(()=>{
        fetch('https://fpqn16v2kg.execute-api.ap-southeast-1.amazonaws.com/test/carpark_namemap').then(
        response => response.json()
        ).then(
            data => setNameMap(data['body'])
            ).catch(rejected => {
                console.log(rejected);
            });

            const socket = new WebSocket("wss://vp4ougv411.execute-api.ap-southeast-1.amazonaws.com/test")
            socket.addEventListener("message", event => {
            setparkingData( JSON.parse(event.data)['body']['carParkStatus'])
            });
            socket.addEventListener("open", event => {
                socket.send(JSON.stringify({
                    "action":"init",
                    "userid":"E0N3UpYB3zVzyJLUq2wiQ6akhw52"
                    }))
              });
    },[])
     

    // function createRectangles() {

    //     const rectangles = [];
        
    //     for (let i = 0; i < 8; i++) {
    //         for (let j = 0; j < 8; j++) {
    //         rectangles.push(
    //             <div
    //             key={`${i}-${j}`}
    //             style={{
    //                 width: '150px',
    //                 height: '80px',
    //                 backgroundColor: 'grey',
    //                 display: 'inline-block',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 border: '1px solid black',
    //                 boxSizing: 'border-box',
    //             }}
    //             >
    //                 Parking-Slot {i * 8 + j + 1}
    //             </div>
    //         );

    //         if ((j + 1) % 8 === 0) {
    //             rectangles.push(<br key={`br-${i}-${j}`} />);
    //         }

    //         }
    //     }
        
    //     return rectangles;
          
    //   }

      function getName(dictionary, key) {
        if (dictionary.hasOwnProperty(key)) {
          return dictionary[key];
        } else {
          return undefined;
        }
      }

      function colorRectangle(){
        const nameMapValues = [];
        const parkingDataValues = [];

        for (var i = 1; i <65; i++) {
            const nvalue = getName(nameMap,i);
            if (nvalue !== undefined) {
                nameMapValues.push(nvalue); // push the value into the array
                const pvalue = getName(parkingData,nvalue);
                parkingDataValues.push(pvalue); // push the value into the array
              }
        }

        const rectangles = [];

        var backgroundColor = 'grey';

        for (let i = 0; i < 8; i++) {
          for (let j = 0; j < 8; j++) {
            const k = i*8 + j;

            
            if (parkingDataValues[k]<100){
                // backgroundColor = 'red';
                // Normalize the value to a range of 0 to 1
                var normalizedValue = (parkingDataValues[k]- 1) / (100 - 1);

                // Calculate the hue, with 0 being green and 240 being red
                //var h = (1.0 - normalizedValue) * 120;
              
                //backgroundColor = "hsl(" + h + ", 100%, 50%)"
                var h = 120- (normalizedValue * 120);
                // Return the HSL color string
                backgroundColor = "hsl(" + h + ", 100%, 50%)";
            }
            // else if (parkingDataValues[k] === false){
            //     backgroundColor = 'green';
            // }
            else{
                backgroundColor = 'grey';
            }
      
            rectangles.push(
              <div
                key={`${i}-${j}`}
                style={{
                  width: '170px',
                  height: '100px',
                  backgroundColor,
                  // borderRadius: '11px',
                  color: '#fff',
                  fontWeight: 700,
                  lineHeight: '29px',
                  padding: '.8em 1em',
                  textAlign: 'center',
                  display: 'inline-block',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid black',
                  boxSizing: 'border-box',
                }}
              >

              <div style={{
                color: 'black',
              }}>
                Parking-Slot {i * 8 + j + 1}
              </div>
                
                {/* {parkingDataValues[k]} */}
              </div>
            );
      
            
          }
        }

        return (

          rectangles
          );
          
      }


    return <>

    <div>{colorRectangle()}</div> 
    </>
}